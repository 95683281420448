import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { TrainingCourseTagsSidebar } from "./TrainingCourseTagsSidebar_view";

export const TRAINING_COURSE_TAGS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  item: FP.Entities.ITrainingCourseSummary,
  onAssignSuccess: any
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={`${item.name}`} field={I18n.t("phrases.tags")} />,
    content: <TrainingCourseTagsSidebar projectId={projectId} itemId={item.id} onAssignSuccess={onAssignSuccess} />
  };
};
