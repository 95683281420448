import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Panel } from "../../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { Tag } from "../../../../../../../../components/ui/Tag";
import PermissionsContext from "../../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../localization/I18n";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { TrainingCourseTagsSidebarModel } from "./TrainingCourseTagsSidebar_model";
import { ManageTrainingCourseTagForm } from "./ManageTrainingCourseTagForm";

export interface TrainingCourseTagsSidebarProps {
  projectId: number;
  itemId: number;
  onAssignSuccess?: any;
}

export const TrainingCourseTagsSidebar: React.FC<TrainingCourseTagsSidebarProps> = observer(props => {
  const { projectId, itemId, onAssignSuccess } = props;
  const organisationId = useCurrentOrganisationId();
  const [model, setModel] = useState(
    () => new TrainingCourseTagsSidebarModel(organisationId, projectId, itemId, onAssignSuccess)
  );

  useEffect(() => {
    if (model.projectId !== projectId) {
      setModel(new TrainingCourseTagsSidebarModel(organisationId, projectId, itemId, onAssignSuccess));
    }
    // eslint-disable-next-line
  }, [organisationId, projectId, itemId, model.projectId]);

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (!model || model.isLoading) return <PositionedSpinner />;

  const canEdit = PermissionsContext.canEditField(PermissionFields.STAKEHOLDERS, organisationId, projectId);
  const { trainingCourse } = model;

  return (
    <div className="container-fluid">
      {canEdit ? (
        <div className="row mb-4">
          <div className="col">
            <ManageTrainingCourseTagForm
              projectId={projectId}
              organisationId={organisationId}
              trainingCourse={model.trainingCourse}
              onAssignSuccess={onAssignSuccess}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            {trainingCourse.tags.length > 0 ? (
              trainingCourse.tags.map(t => (
                <Tag
                  tagText={t.text}
                  isEditable={false}
                  testId={t.id + ""}
                  key={t.id}
                  isNew={false}
                  onClick={e => {
                    model.removeTag(t.id);
                  }}
                />
              ))
            ) : (
              <div>
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noTags")}</div>
                </Panel.Panel>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
