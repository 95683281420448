import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { ButtonTypes } from "../../../../components/ui/Button";
import { Panel } from "../../../../components/ui/Panel";
import { ActivityCardModel } from "../../../../components/widgets/activityCard/ActivityCard_model";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IUiAction, UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../core/util/BaseModel";
import CommentsApi, { CommentsApi as ICommentsApi } from "../../../../services/api/v2/comments/Comments.api";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";

export interface ICommentListViewConfig {
  searchAttribute: string;
  id: number;
  title?: React.ReactNode;
  placeholderText: string;
  description?: React.ReactNode;
  projectId: number;
  organisationId: number;
  onAssignSuccess?: any;
}

export class CommentListViewModel extends BaseModel {
  commentsProvider: ICommentsApi;
  toastService: IToasterService;
  httpProgress: IProgressIndicatorModel;
  modalService: IModalContextModel;
  projectId: number;
  authUser: FP.Entities.IUser;
  @observable config: ICommentListViewConfig = null;
  @observable commentInput: string = "";
  @observable isLoadingComments: boolean = true;
  @observable errorMessage: string = "";
  @observable searchInput: string = "";
  @observable.ref comments: FP.Entities.IComment[];
  @observable.ref filteredComments: FP.Entities.IComment[];
  onAssignSuccess: any;

  activityCardActions: IUiAction<ActivityCardModel>[] = [
    {
      id: "edit",
      label: I18n.t("phrases.edit"),
      rendersIn: UiActionRenderers.BUTTON,
      componentProps: {
        type: ButtonTypes.LINK
      },
      onAction: (ev, context) => {
        context.enterEditMode();
      }
    },
    {
      id: "remove",
      label: I18n.t("phrases.remove"),
      onAction: async (ev, context) => {
        this.confirmDeleteComment(context.comment);
      },
      componentProps: {
        type: ButtonTypes.LINK
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  organisationId: number;

  /**
   *
   */
  constructor(projectId: number, authUser: FP.Entities.IUser, initOpts: ICommentListViewConfig, onAssignSuccess?: any) {
    super();
    makeObservable(this);
    this.setConfig(initOpts);
    this.organisationId = initOpts.organisationId;
    this.authUser = authUser;
    this.commentsProvider = CommentsApi;
    this.projectId = projectId;

    this.toastService = ToasterService;
    this.httpProgress = ProgressIndicatorModel;
    this.modalService = ModalContext;
    this.onAssignSuccess = onAssignSuccess;
  }

  onMount = () => {
    this.loadComments();
  };

  onUnmount = () => {};

  @action
  loadComments = async () => {
    this.isLoadingComments = true;
    if (!this.config.id) {
      this.toastService
        .showErrorToast(TOASTER_TOAST_TIME.SLOW)
        .setContent(<p>No Id from {this.config.searchAttribute} was specified</p>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
      return;
    }

    const res = await this.commentsProvider.getFiltered(this.organisationId, this.projectId, {
      sorts: "-createdAt",
      filters: this.config.searchAttribute + "==" + this.config.id
    });

    if (!res || res.isError) {
      this.errorMessage = I18n.t("errors.loadComments");
      return;
    }

    console.log("res.payload:", res.payload);
    this.setComments(res.payload);
    this.setIsLoadingComments(false);
  };

  @action
  setIsLoadingComments = (isLoadingComments: boolean) => {
    this.isLoadingComments = isLoadingComments;
  };

  @action
  createComment = async () => {
    if (this.commentInput.length === 0) return;
    const data: FP.Entities.IComment = {
      content: this.commentInput,
      owner: this.authUser,
      projectId: this.config.projectId
    };
    data[this.config.searchAttribute] = this.config.id;

    this.httpProgress.showTopProgressBarVisible();
    const res = await this.commentsProvider.createFN(this.organisationId, this.projectId, data);
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) {
      this.toastService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.createComment"));
      return;
    }

    await this.onAssignSuccess();

    this.resetComment();
    this.loadComments();
  };

  @action
  updateComment = async (comment: FP.Entities.IComment) => {
    this.httpProgress.showTopProgressBarVisible();
    const res = await this.commentsProvider.updateFN(this.organisationId, this.projectId, comment.id, comment);
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) {
      this.toastService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.updateComment"));
      return;
    }

    this.loadComments();
  };

  confirmDeleteComment = (comment: FP.Entities.IComment) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmDeleteComment")}</div>
          </div>
        </div>,
        I18n.t("phrases.delete"),
        I18n.t("phrases.cancel"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          this.httpProgress.showTopProgressBarVisible();
          const res = await this.commentsProvider.removeFN(this.organisationId, this.projectId, comment.id);
          this.httpProgress.hideTopProgressBarVisible();

          if (!res || res.isError) return;

          this.loadComments();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  handleInputChange = (value: string) => {
    this.setCommentInput(value);
  };

  @action
  setCommentInput = (value: string) => {
    this.commentInput = value;
  };

  @action
  setComments = (comments: FP.Entities.IComment[]) => {
    this.comments = comments;
    this.filterComments();
  };

  @action
  resetComment = () => {
    this.commentInput = "";
  };

  @action
  setConfig = (config: Partial<ICommentListViewConfig>) => {
    this.config = { ...this.config, ...config };
  };

  @action
  setSearchInput = (value: string) => {
    this.searchInput = value;
    this.filterComments();
  };

  @action
  resetSearchInput = () => {
    this.searchInput = "";
    this.filterComments();
  };

  @action
  filterComments = () => {
    if (this.searchInput) {
      const lowerSearch = this.searchInput.toLowerCase();
      this.filteredComments = this.comments.filter(comment => {
        const lowerName = `${comment.owner.firstName} ${comment.owner.lastName}`.toLowerCase();
        const lowerContent = comment.content
          .replace(/<a\s+href=['"]([^'"]*)['"][^>]*>(.*?)<\/a>/g, " $2 ($1) ") // Keeps anchor text and URL in parentheses
          .replace(/<[^>]+>/g, " ") // Removes all remaining tags
          .replace(/\s+/g, " ") // Removes extra spaces
          .trim()
          .toLowerCase();

        return lowerContent.includes(lowerSearch) || lowerName.includes(lowerSearch);
      });
    } else {
      this.filteredComments = this.comments;
    }
  };
}
