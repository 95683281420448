import _ from "lodash";
import { Link } from "react-router-dom";
import { AutocompletePerson } from "../../../../../../../../components/ui/AutocompletePersonOption";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL, REMOVE_UNSAFE_CHARACTERS } from "../../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../localization/I18n";
import Pages from "../../../../../../../../routes/InsightRoutes";
import { ProjectStakeholderOwnersApi } from "../../../../../../../../services/api/v2/projectStakeholderOwners/ProjectStakeholderOwners.api";
import { ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi } from "../../../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";

export const getProjectStakeholderOwnerFormFields = (
  projectStakeholderOwnerProvider: ProjectStakeholderOwnersApi,
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi,
  organisationId: number,
  projectId: number,
  projectStakeholder: FP.Entities.IProjectStakeholder,
  onAddOwner: () => void
) => {
  const addOwner = async (owner: any) => {
    let res = await projectStakeholderOwnerProvider.addStakeholderOwner(organisationId, projectId, {
      projectStakeholderId: projectStakeholder.id,
      ownerId: owner.userId
    });
    onAddOwner();
    if (!res) return;
  };

  const onwers: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "ownerNames",
    label: <label htmlFor={"owner"}>{I18n.t("forms.organisationOwner")} *</label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    onItemSelected: e => addOwner(e as any),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await projectTeamUserPermissionsProvider.getUsersInProject(organisationId, projectId, {
        page: 1,
        pageSize: 100000
      });
      const currentOwners = await projectStakeholderOwnerProvider
        .getStakeholderOwnersByProjectStakeholderId(organisationId, projectId, projectStakeholder.id)
        .then(res => res.payload.map((e: any) => e.id));
      if (res?.payload) {
        let sortedUsers = _.orderBy(res.payload, [user => user.firstName.toLowerCase()]);
        self.setOptions(currentOwners.filter((t: any) => !sortedUsers.includes(t.userId)));
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => REMOVE_UNSAFE_CHARACTERS(`${e.firstName} ${e.lastName}`));
    },
    value: projectStakeholder?.ownerNames,
    isHidden: false,
    valueLabelFn: e => e?.text,
    allowFreeText: false,
    isTagSelector: false,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: (
      <div>
        {I18n.t("forms.noProjectTeamMembers")}

        <Link
          className="ml-2"
          target="_blank"
          to={Pages.projects.settings.permissions.generateLink(organisationId, projectId)}
        >
          Add
        </Link>
      </div>
    ) as any,
    searchResultHint: <p className="mb-0 pl-3 pb-1 pt-2">{I18n.t("forms.onwersSearchResultHint")}</p>
  };

  const fields = [];

  fields.push(onwers);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
