import { useState } from "react";
import { Outlet } from "react-router-dom";
import { ClaimsContextProvider } from "../../core/auth/authorise";
import { ModalContextProvider } from "../../core/modalZ/context/ModalContext";
import { Navigation } from "../../pages/insight/navigation/Navigation";
import {
  OrganisationContextModel,
  OrganisationContextProvider
} from "../../services/local/organisationContext/OrganisationContextModel";

export const InsightApp: React.FC = () => {
  const [organisationContext] = useState(() => new OrganisationContextModel());

  return (
    <ClaimsContextProvider>
      <OrganisationContextProvider model={organisationContext}>
        <ModalContextProvider>
          <Navigation>
            <Outlet />
          </Navigation>
        </ModalContextProvider>
      </OrganisationContextProvider>
    </ClaimsContextProvider>
  );
};
