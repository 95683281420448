import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { PaginationModel } from "../../../components/widgets/pagination/Pagination_model";
import { FilterModel, FilterOperator, IFilterAttribute, IFilterModel } from "../../../core/filter/Filter_model";
import I18n from "../../../core/localization/I18n";
import { Enums } from "../../../enums";
import UserReportsApi from "../../../services/api/v2/userReports/UserReports.api";
import { useCurrentOrganisationId } from "../../../services/local/organisationContext/OrganisationContextModel";
import { PageTitle } from "../../../services/local/pageHeader/PageHeader";
import { UserActionsModel } from "../../change/dashboard/userReports/userActions/UserActions_model";
import { UserActionsPage } from "../../change/dashboard/userReports/userActions/UserActions_view";
import { GetActionListFilters } from "./Dashboard_fields";
import { Actions } from "./actions/Actions_view";
import { Impacts } from "./impacts/Impacts_view";
import { Stakeholders } from "./stakeholders/Stakeholders_view";

export const Dashboard: React.FC = () => {
  return (
    <div className="dashboard mt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>{I18n.t("phrases.myDashboard")}</h1>
          </div>
        </div>
        <div className="row">
          <Actions shouldShowSeeAllButton={true} />

          <Impacts shouldShowSeeAllButton={true} />
        </div>
        <div className="row">
          <div className="col-6">
            <Stakeholders />
          </div>
          {/* <div className="col-6">
            <ActionTypes />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export const OverdueActionsContent: React.FC<{}> = () => {
  const organisationId = useCurrentOrganisationId();
  const { type } = useParams() as any;
  let userReportProvider = UserReportsApi;
  const [paginationModel] = useState(() => new PaginationModel());
  const config = {
    paginationModel,
    initOpts: {
      filterCb: async filterOptions => await userReportProvider.getOverdueActions(organisationId, filterOptions)
    }
  };
  let filterModel: IFilterModel<FP.Entities.IAction> = new FilterModel(config);

  let userActionsModel = new UserActionsModel(filterModel, organisationId);
  userActionsModel.title = (
    <h1 className="d-inline-block mb-0 mt-1">
      {I18n.t("phrases.myActions")} | {I18n.t("phrases.overdueActions")}
    </h1>
  );
  userActionsModel.paginationModel = paginationModel;
  SetUserActionFilter(organisationId, filterModel, userActionsModel.setActions, "overdueActions");
  filterModel.setFilterValue("userRelation", type);
  paginationModel.setConfig({ onPageClick: userActionsModel.loadPageData });
  return (
    <>
      <PageTitle title={`${I18n.t("phrases.myDashboard")} | ${I18n.t("phrases.overdueActions")}`} />
      <UserActionsPage />
    </>
  );
};

export const SetUserActionFilter = (
  orgId: number,
  filterModel: IFilterModel<FP.Entities.IAction>,
  dataSetter: (actions: FP.Entities.IAction[]) => void,
  reportFilter?: "overdueActions" | "upcomingActions",
  pageSize: number = 5
) => {
  const nameFilter: IFilterAttribute = {
    key: "name",
    label: I18n.t("filters.name"),
    value: [],
    operator: FilterOperator.CONTAINS
  };

  const refFilter: IFilterAttribute = {
    key: "refNumber",
    label: I18n.t("filters.refNumber"),
    value: [],
    operator: FilterOperator.CONTAINS
  };

  const lifeCycleFilter: IFilterAttribute = {
    key: "lifecycleStatus",
    value: [Enums.LifecycleStatus.Active + ""],
    isHidden: true,
    operator: FilterOperator.EQUALS
  };

  const ragStatusFilter: IFilterAttribute = {
    key: "ragStatus",
    label: I18n.t("filters.ragStatus"),
    operator: FilterOperator.EQUALS,
    value: [],
    valueRenderer: (str, obj) => {
      return Enums.Translator.RagStatus(parseInt(str));
    }
  };

  const projectId: IFilterAttribute = {
    key: "projectId",
    label: I18n.t("filters.project"),
    operator: FilterOperator.EQUALS,
    value: [],
    valueRenderer: (str, obj) => {
      return str;
    }
  };

  const userRelation: IFilterAttribute = {
    key: "userRelation",
    operator: FilterOperator.EQUALS,
    value: ["assignee"],
    isHidden: true
  };

  const organisationId: IFilterAttribute = {
    key: "project.OrganisationId",
    label: I18n.t("filters.organisation"),
    operator: FilterOperator.EQUALS,
    value: []
  };

  const actionType: IFilterAttribute = {
    key: "actionTypeId",
    label: I18n.t("filters.actionType"),
    operator: FilterOperator.EQUALS,
    value: []
  };

  filterModel.addSort({ key: "actualEndDate", isAsc: false });

  if (reportFilter) {
    const reportOn: IFilterAttribute = {
      key: reportFilter,
      useWithoutValue: true,
      isHidden: true
    };

    filterModel.addFilter(reportOn);
  }

  filterModel.addFilter(nameFilter);
  filterModel.addFilter(refFilter);
  filterModel.addFilter(lifeCycleFilter);
  filterModel.addFilter(ragStatusFilter);
  filterModel.addFilter(userRelation);
  filterModel.addFilter(projectId);
  filterModel.addFilter(organisationId);
  filterModel.addFilter(actionType);

  filterModel.setConfig({
    formFields: GetActionListFilters(orgId, null, ["name", "refNumber", "projectId", "organisationId", "actionTypeId"]),
    onDataLoaded: dataSetter,
    pageSize
  });
  filterModel.loadData();
};
