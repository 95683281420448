import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import http from "../Http";

export class TagsApi extends FilteredOrganisationApiModel<FP.Entities.ITag> {
  controller: string = "tags";

  constructor(http: AxiosInstance) {
    super(http, "tags");
  }

  searchByNameAsync = async (
    organisationId: number,
    searchPhrase: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag[]>> => {
    let encodedSearchPhrase = encodeURI(searchPhrase);
    let res = await this.http.get(`${this.url}/${organisationId}/tags/search/${encodedSearchPhrase}`, config);
    return res.data;
  };

  getDetailedAsync = async (
    organisationId: number,
    tagId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag>> => {
    let res = await this.http.get(`${this.url}/${organisationId}/tags/${tagId}`, config);
    return res.data;
  };

  addActionTagAssoc = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/actionTags/${actionId}`,
      tag,
      config
    );
    return res.data;
  };

  addImpactTagAssoc = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/impactTags/${impactId}`,
      tag,
      config
    );
    return res.data;
  };

  addProjectTagAssoc = async (
    organisationId: number,
    projectId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(`${this.url}/${organisationId}/projects/${projectId}/tags/projectTags`, tag, config);
    return res.data;
  };

  getAllAsync = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag[]>> => {
    let res = await this.http.get(`${this.url}/${organisationId}/tags`, config);
    return res.data;
  };

  getFilteredWithUsageCount = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag[]>> => {
    let query = this.getRequestQuery(filterOptions);
    let res = await this.http.get(`${this.url}/${organisationId}/tags/usageCount` + query, config);
    return res.data;
  };

  removeActionTagAssoc = async (
    organisationId: number,
    projectId: number,
    tagId: number,
    actionId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/tags/${tagId}/actionTags/${actionId}`,
      config
    );
    return res.data;
  };

  removeImpactTagAssoc = async (
    organisationId: number,
    projectId: number,
    tagId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/tags/${tagId}/impactTags/${impactId}`,
      config
    );
    return res.data;
  };

  removeProjectStakeholderTagAssoc = async (
    organisationId: number,
    projectId: number,
    tagId: number,
    pStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/tags/${tagId}/projectStakeholderTags/${pStakeholderId}`,
      config
    );
    return res.data;
  };

  addProjectStakeholderTagAssoc = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/projectStakeholderTags/${projectStakeholderId}`,
      tag,
      config
    );
    return res.data;
  };

  removeAudienceStakeholderTagAssoc = async (
    organisationId: number,
    projectId: number,
    tagId: number,
    audStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/tags/${tagId}/audienceStakeholderTags/${audStakeholderId}`,
      config
    );
    return res.data;
  };

  addAudienceStakeholderTagAssoc = async (
    organisationId: number,
    projectId: number,
    audStakeholderId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/audienceStakeholderTags/${audStakeholderId}`,
      tag,
      config
    );
    return res.data;
  };

  addProjectProcessTagAssoc = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/projectProcessTags/${projectStakeholderId}`,
      tag,
      config
    );
    return res.data;
  };

  removeProjectProcessTagAssoc = async (
    organisationId: number,
    projectId: number,
    tagId: number,
    pStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/tags/${tagId}/projectProcessTags/${pStakeholderId}`,
      config
    );
    return res.data;
  };

  addTrainingCourseTagAssoc = async (
    organisationId: number,
    projectId: number,
    trainingCourseId: number,
    tag: FP.Entities.ITag,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/tags/trainingCourseTags/${trainingCourseId}`,
      tag,
      config
    );
    return res.data;
  };

  removeTrainingCourseTagAssoc = async (
    organisationId: number,
    projectId: number,
    tagId: number,
    trainingCourseId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/tags/${tagId}/trainingCourseTags/${trainingCourseId}`,
      config
    );
    return res.data;
  };
}

let instance = new TagsApi(http);

export default instance;
