import React, { useState } from "react";
import { Button } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../localization/I18n";
import { useModalContext } from "../../../../../../../modalZ/context/ModalContext";
import TagsApi from "../../../../../../../../services/api/v2/tags/Tags.api";
import { getTrainingCourseTagFormFields } from "./TrainingCourseTagsSidebar_formFields";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

interface ManageTrainingCourseTagFormProps {
  organisationId: number;
  projectId: number;
  trainingCourse: FP.Entities.ITrainingCourse;
  onAssignSuccess?: any;
}

class ManageTrainingCourseTagFormModel {
  formModel: SingleFormModel;

  constructor(
    organisationId: number,
    projectId: number,
    trainingCourse: FP.Entities.ITrainingCourse,
    onAssignSuccess?: any
  ) {
    this.formModel = new SingleFormModel();

    const tagsProvider = TagsApi;
    let formFields = getTrainingCourseTagFormFields(
      tagsProvider,
      organisationId,
      projectId,
      trainingCourse,
      onAssignSuccess
    );
    this.formModel.formFields = formFields;
  }
}

export const ManageTrainingCourseTagForm: React.FC<ManageTrainingCourseTagFormProps> = props => {
  const { trainingCourse, projectId, organisationId, onAssignSuccess } = props;
  const modalService = useModalContext();

  let [model] = useState(
    () => new ManageTrainingCourseTagFormModel(organisationId, projectId, trainingCourse, onAssignSuccess)
  );

  return (
    <div>
      <SingleForm model={model.formModel} />
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex">
            <Button
              className="ml-auto"
              onClick={() => {
                modalService.hide();
              }}
            >
              {I18n.t("phrases.done")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
