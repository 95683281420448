import _ from "lodash";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL, REMOVE_UNSAFE_CHARACTERS } from "../../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../forms/helpers/FormFieldMappers";
import { filterOutExistingTagVals } from "../../../../../../GridHelpers";
import I18n from "../../../../../../../localization/I18n";
import { TagsApi } from "../../../../../../../../services/api/v2/tags/Tags.api";

export const getTrainingCourseTagFormFields = (
  tagsProvider: TagsApi,
  organisationId: number,
  projectId: number,
  trainingCourse: FP.Entities.ITrainingCourse,
  onAssignSuccess?: any
) => {
  const removeTag = async (tag: FP.Entities.ITag) => {
    let res = await tagsProvider.removeTrainingCourseTagAssoc(organisationId, projectId, tag.id, trainingCourse.id);
    if (!res) return;
    await onAssignSuccess();
  };

  const addTag = async (tag: FP.Entities.ITag) => {
    let res = await tagsProvider.addTrainingCourseTagAssoc(organisationId, projectId, trainingCourse.id, tag);
    if (!res) return;
    await onAssignSuccess();
  };

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    placeholder: I18n.t("placeholders.searchOrCreateTags"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: FP.Entities.ITag) => e.text} />
    ),
    onItemSelected: e => addTag(e as any),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await tagsProvider.getAllAsync(organisationId);

      if (res?.payload) {
        const currentValues = self.extractValue();
        let sortedTags = _.orderBy(res.payload, [tag => tag.text.toLowerCase()]);
        self.setOptions(filterOutExistingTagVals(currentValues, sortedTags));
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => REMOVE_UNSAFE_CHARACTERS(e.text));
    },
    value: trainingCourse?.tags,
    isHidden: false,
    valueLabelFn: e => e?.text,
    allowFreeText: true,
    removeItemFn: (item, idx) => {
      removeTag(item);
    },
    isTagSelector: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.tagsResultsNotFound"),
    searchResultHint: <p className="mb-0 pl-3 pb-1 pt-2">{I18n.t("forms.tagsSearchResultHint")}</p>
  };

  const fields = [];

  fields.push(tags);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
