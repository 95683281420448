import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../util/BaseModel";
import TrainingCoursesApi, {
  TrainingCoursesApi as ITrainingCoursesApi
} from "../../../../../../../../services/api/v2/trainingCourses/TrainingCourses.api";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../../../services/api/v2/tags/Tags.api";

export class TrainingCourseTagsSidebarModel extends BaseModel {
  trainingCoursesProvider: ITrainingCoursesApi;
  tagsProvider: ITagsApi;
  @observable isLoading: boolean = true;
  @observable.ref trainingCourse: FP.Entities.ITrainingCourse;
  organisationId: number;
  projectId: number;
  trainingCourseId: number;
  onAssignSuccess?: any;

  constructor(organisationId: number, projectId: number, trainingCourseId: number, onAssignSuccess) {
    super();
    makeObservable(this);
    this.trainingCoursesProvider = TrainingCoursesApi;
    this.tagsProvider = TagsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.trainingCourseId = trainingCourseId;
    this.onAssignSuccess = onAssignSuccess;
  }

  onMount = async () => {
    await this.loadTrainingCourse(this.trainingCourseId);
  };

  @action
  setTrainingCourse = (trainingCourse: FP.Entities.ITrainingCourse) => {
    this.trainingCourse = trainingCourse;
    this.isLoading = false;
  };

  @action
  loadTrainingCourse = async (trainingCourseId: number) => {
    let res = await this.trainingCoursesProvider.getDetailedById(this.organisationId, this.projectId, trainingCourseId);
    if (!res || res.isError) return;
    this.setTrainingCourse(res.payload);
  };

  @action
  removeTag = async (tagId: number) => {
    let res = await this.tagsProvider.removeTrainingCourseTagAssoc(
      this.organisationId,
      this.projectId,
      tagId,
      this.trainingCourse.id
    );
    if (!res || res.isError) return;
    await this.onAssignSuccess();
  };
}
